jQuery(document).ready(function() {
  var controller = new ScrollMagic.Controller();

  var projects = Array.prototype.slice.call(
    document.querySelectorAll(".two-col-parallax")
  );

  var self = this;

  projects.forEach(function(self) {
    var image = self.querySelectorAll("img");
    var text = self.querySelectorAll("p");
    var plus1 = self.querySelectorAll(".plus-block svg.p1");
    var plus2 = self.querySelectorAll(".plus-block svg.p2");
    var plus3 = self.querySelectorAll(".plus-block svg.p3");

    var sceneOne = new TimelineMax();

    if (jQuery(document).width() > 768) {
      sceneOne
        .fromTo(image, 0.5, { y: 70 }, { y: -70 }, image)
        .fromTo(plus1, 0.5, { y: 0 }, { y: -500 }, image)
        .fromTo(plus2, 0.5, { y: 0 }, { y: -420 }, image)
        .fromTo(plus3, 0.5, { y: 0 }, { y: -370 }, image);
    } else {
      sceneOne
        .fromTo(plus1, 0.5, { y: 0 }, { y: -500 }, image)
        .fromTo(plus2, 0.5, { y: 0 }, { y: -420 }, image)
        .fromTo(plus3, 0.5, { y: 0 }, { y: -370 }, image);
    }

    var scene1 = new ScrollMagic.Scene({
      triggerElement: self,
      triggerHook: "onEnter",
      duration: 5000,
      offset: 0
    })
      .setTween(sceneOne)
      .setClassToggle(self, "active")
      .addTo(controller);
  });

  // Footer fade in.
  var footerPlus1 = jQuery(".footer-plus-block img");

  var footerScene = new TimelineMax();

  footerScene.fromTo(
    footerPlus1,
    1.8,
    { opacity: 0, y: 20 },
    { opacity: 0.3, y: 0 }
  );

  var smSceneFooter = new ScrollMagic.Scene({
    triggerElement: "footer.footer",
    triggerHook: "onEnter",
    duration: 0,
    offset: 150
  })
    .setTween(footerScene)
    .setClassToggle(self, "active")
    .addTo(controller);

  // Icon fade in
  var servicesSection = jQuery(".services-section .row .grid-item");

  var servicesScene = new TimelineMax();

  servicesScene.fromTo(
    servicesSection,
    0.5,
    { opacity: 0, y: 20 },
    {
      opacity: 0.8,
      y: 0,
      stagger: 0.25,
      onStartParams: [servicesSection],
      onStart: function(obj) {
        obj
          //.find(".icon")
          .addClass("inplace");
        console.log(this.target); //[object Window]
      }
    }
  );

  var smSceneServices = new ScrollMagic.Scene({
    triggerElement: ".services-section",
    triggerHook: "onEnter",
    duration: 0,
    offset: 150
  })
    .setTween(servicesScene)
    .setClassToggle(self, "active")
    .addTo(controller);

  // Icon fade in services header
  var servicesSection = jQuery(".services-head");

  var servicesScene = new TimelineMax();

  servicesScene.fromTo(
    jQuery(servicesSection).find(".icon"),
    0.5,
    { opacity: 0, y: 20 },
    {
      opacity: 0.8,
      y: 0,
      onStartParams: [servicesSection],
      onStart: function(obj) {
        obj
          //.find(".icon")
          .addClass("inplace");
        console.log(this.target); //[object Window]
      }
    }
  );

  var smSceneServices = new ScrollMagic.Scene({
    triggerElement: ".services-head",
    triggerHook: "onEnter",
    duration: 0,
    offset: 150
  })
    .setTween(servicesScene)
    .setClassToggle(self, "active")
    .addTo(controller);

  // End ready state
});
